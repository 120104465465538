@import "globalStyles.css";

#search-container {
  margin-bottom: 1%;
  padding: 30px 5%;
  color: var(--color-black);
}
.search-loading-container {
  background-color: var(--color-white-trans);
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  right: -60px;
  transform: translateX(-60px);
  transition: var(--default-transition);
}
.search-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around; /* space-between | space-around | space-evenly */
  margin: 20px 0px;
  gap: 15px 10px;
}

#search-container h3 {
  font-size: 1.2rem;
  margin: 0px;
  padding: auto 0px;
  height: 100%;
  top: 6px;
  white-space: nowrap;
  color: var(--color-black);
}

#search-container input,
.search-flex select,
option {
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
  border: 3px solid var(--color-grey-2);
  border-radius: 10px;
  height: 40px;
  padding: 5px 15px;
  /* margin-right: 15px; */
  font-size: 1rem;
  width: 100%;
  transition: all 0.5s linear;
}

#search-container button {
  background-color: var(--color-accent);
  padding: 5px 25px 5px 25px;
  color: white;
  border-radius: 25px;
  transition: var(--default-transition);
}

#search-container button:hover {
  background-color: var(--color-accent-hover);
  cursor: pointer;
}

#search-container input:focus,
#search-flex select:focus {
  background-color: var(--color-white);
  border: 3px solid var(--color-accent-hover);
}

.label-input-container {
  display: flex;
  margin: 0 20px;
}

.label-input-container label {
  margin-right: 15px;
}

#search-flex > select:last-of-type {
  margin-right: 0px !important;
}

#search-flex > label {
  margin-right: 10px;
}

#search-box-container {
  display: flex;
  align-items: center;
  width: 100%;
}

#search-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#search {
  width: 100%;
  margin: auto;
  background-image: url(../svg/magnifying-glass-solid.svg) no-repeat;
  padding-left: 30px;
}

.magnifying-glass {
  height: 25px;
  fill: var(--color-grey-3);
  position: relative;
  right: 10px;
  /* top: 10px; */
}

#difficulty-level {
  min-width: 55px !important;
  max-width: 75px;
  margin-left: 5px;
}

#technique-container {
  background-color: var(--color-white);
  border: 3px solid var(--color-grey-2);
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  margin: 15px 0px;
  transition: all 0.5s linear;
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}

.technique-tag {
  background-color: var(--color-grey-1);
  width: fit-content;
  height: fit-content;
  padding: 4px 9px;
  border-radius: 25px;
  margin: 3px;
}

.technique-tag p {
  color: var(--color-black);
}

.technique-tag:hover {
  cursor: pointer;
  box-shadow: 2px 2px 5px 0px var(--color-black-trans);
}

.selected {
  background-color: var(--color-accent);
}

.selected p {
  color: white !important;
}

.toggle-switch {
    position: relative;
    float: right;
    width: 60px;
    height: 30px;
    min-height: 30px !important;
    background-color: var(--color-grey-1);
    border-radius: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }
  
  .switch {
    width: 25px;
    height: 25px;
    background-color: #3498db;
    border-radius: 50%;
    position: absolute;
    transition: 0.3s ease-in-out;
  }
  
  .switch.checked {
    transform: translateX(30px);
  }
  
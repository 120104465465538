@import 'globalStyles.css';

.navbar .links a {
  justify-content: center;
  align-items: center;
  color: var(--color-black);
  font-size: 20px;
  padding: 12px 25px;
  margin: 5px 0px;
  text-decoration: none;
  transition: var(--default-transition);
}

.hamburger {
  width: 35px;
  height: 35px;
  fill: var(--color-grey-4);
  display: none;
}

.hamburger-line-container {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.hamburger-line {
  width: 40px;
  height: 5px;
  margin-bottom: 6px;
  border-radius: 5px;
  background-color: var(--color-grey-3);
  transition: var(--default-transition);
}

.navbar .links a.active {
  background-color: var(--color-accent);
  max-width: 150px;
  border-radius: 25px;
  color: white;
  transition: var(--default-transition);
}

.navbar .links a.active:hover {
  background-color: var(--color-accent-hover);
  color: white;
  transition: var(--default-transition);
}

.navbar .links a:hover {
  color: var(--color-accent);
  cursor: pointer;
}

.navbar .links {
  text-align: right;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  /* padding: 500px auto !important; */
}

.navbar {
  box-shadow: var(--box-shadow-1);
  position: fixed;
  display: block;
  top: 0;
  background-color: var(--color-white);
  z-index: 2;
}

.nav-content {
  margin: 0 !important;
  padding: 0px !important;
}

.navbar h1 {
  text-align: left;
  color: var(--color-black);
}

.piece-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}
.piece-preview:hover {
  box-shadow: var(--box-shadow-1);
}
.piece-preview h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 8px;
}

@media (max-width: 1150px) {
  .nav-bar-middle {
    width: 15% !important;
  }
}

@media (max-width: 1000px) {
  .nav-bar-middle {
    width: 5% !important;
  }
}

@media (max-width: 890px) {
  .nav-bar-middle {
    display: none;
  }
}

@media (max-width: 815px) {
  .links {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    /* transform: translateY(0%) !important; */
    /* display: none; */
    position: absolute !important;
    background-color: var(--color-white);
    width: 100% !important;
    box-shadow: var(--box-shadow-bottom);
    top: -500%;
    transition: var(--default-transition);
  }

  .links a {
    text-align: center;
    width: 30%;
    min-width: 120px;
  }

  .navbar .col-1 {
    display: none;
  }

  .hamburger {
    display: flex;
    align-items: center;
    /* height: 100%; */
    justify-content: center;
    right: 3%;
    top: 25%;
    position: absolute;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Gurajada&family=Mukta+Vaani:wght@200;300;400;700;800&display=swap');

html,
body,
#app,
#app > div {
  height: 100%;
}

[class*='col-'] {
  float: left;
  padding: 15px 25px;
}

/* CSS RESET */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mukta Vaani';
  color: var(--foreground);
  z-index: 1;
  position: relative;
}

input,
textarea,
select,
button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  outline: none;
  appearance: none;
}

.row::after {
  content: '';
  clear: both;
  display: table;
}

.row {
  width: 100% !important;
}

.col-0-5 {
  width: 4.175%;
}

.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

.content {
  max-width: 100vw;
  min-height: 100vh;
  margin: 0px 0px;
}

:root {
  --color-youtube: #ff0000;
  --color-github: #2da44e;
  --color-linkedin: #0a66c2;
  --color-facebook: #1877f2;
  --color-review-star: yellow;

  --box-shadow-small: 0 3px 5px rgba(0, 0, 0, 0.3);
  --font: Oswald, sans-serif;
  --font-paragraph: Noto Sans;

  --default-transition: all 0.4s ease-in-out;
  --fast-transition: all 0.2s ease-in-out;
  --slow-transition: all 1s ease-in-out;
  color: var(--color-white);
}
.light {
  --color-transparent: rgba(0, 0, 0, 0);
  --color-black: #000000;
  --color-white: #ffffff;
  --color-transparent: rgba(0, 0, 0, 0);
  --color-black-trans: rgba(0, 0, 0, 0.3);
  --color-white-trans: rgba(255, 255, 255, 0.65);
  --color-accent: #046cf3;
  --color-blue-dark-2: #0e2f5a;
  --color-blue-dark-1: #2b4687;
  --color-success: #07c400;
  --color-success-hover: #80df7d;
  --color-accent-hover: #659feb;
  --color-accent-light: #fff3b6;
  --color-trans-white: rgba(37, 26, 26, 0);
  --color-grey-0: #f8f8f8;
  --color-grey-1: #e6e6e6;
  --color-grey-1-trans: #e6e6e6d8;
  --color-grey-2: #cccccc;
  --color-grey-3: #818181;
  --color-grey-4: #525252;
  --color-grey-5: #383838;
  --color-grey-6: #161616;
  --color-red: #c63131;
  --box-shadow-1: 0 3px 15px rgba(0, 0, 0, 0.3);
  --box-shadow-bottom: 0 5px 7px rgba(0, 0, 0, 0.3);
}
.dark {
  --color-transparent: rgba(0, 0, 0, 0);
  --color-black: #ffffff;
  --color-white: #303030;
  --color-transparent: rgba(0, 0, 0, 0);
  --color-black-trans: rgba(0, 0, 0, 0.3);
  --color-white-trans: rgba(49, 49, 49, 0.3);
  --color-accent: #046cf3;
  --color-blue-dark-2: #0e2f5a;
  --color-blue-dark-1: #2b4687;
  --color-success: #07c400;
  --color-success-hover: #80df7d;
  --color-accent-hover: #659feb;
  --color-accent-light: #fff3b6;
  --color-trans-white: rgba(37, 26, 26, 0);
  --color-grey-0: #161616;
  --color-grey-1: #383838;
  --color-grey-1-trans: #e6e6e6d8;
  --color-grey-2: #525252;
  --color-grey-3: #818181;
  --color-grey-4: #cccccc;
  --color-grey-5: #e6e6e6;
  --color-grey-6: #f8f8f8;
  --color-red: #c63131;
  --box-shadow-1: 0 3px 15px rgba(0, 0, 0, 0.5);
  --box-shadow-2: 0 7px 17px rgba(0, 0, 0, 0.5);
  --hsl: hsl(204, 93%, 43%);
}

html {
  background: var(--color-white);
  color: var(--foreground) !important;
  transition: var(--default-transition);
}

html::before {
  content: '';
  position: fixed;
  height: 100%;
  width: 100%;
  background: var(--color-black);
  transform: translateX(-100%);
  transition: var(--default-transition);
  z-index: 0;
}

.darkmode::before {
  transform: translateX(0);
}

/* p, h1, h2, a, input, button {
    color: var(--color-white);
} */

a:hover {
  color: var(--color-grey-2);
}

.error {
  color: var(--color-red);
}

#loading-div {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: -10%;
}

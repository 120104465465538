/* AUTHENTICATION PAGES */

.login-background {
  background-color: var(--color-white);
  width: 100vw;
  height: 100vh;
  background-image: url('../../public/img/violinBlur.jpg');
  background-size: cover;
}

.login-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login-box {
  background-color: var(--color-white);
  min-height: fit-content;
  min-width: 400px;
  max-width: 500px;
  max-height: 80vh;
  border-radius: 25px;
  box-shadow: 10px 10px 20px 1px rgba(0, 0, 0, 0.5);
}

.login-box input {
  border: 3px solid var(--color-grey-1);
  border-radius: 15px;
  padding: 10px;
  display: block;
  margin: 8px auto;
  width: 70%;
  color: var(--color-black);
}

.input-error {
  border: 3px solid var(--color-red);
}

.login-box input:focus {
  border: 3px solid var(--color-accent-hover);
}

.login-heading {
  text-align: center;
  color: var(--color-black);
}

.login-button {
  background-color: var(--color-accent);
  padding: 10px;
  width: 70%;
  border-radius: 25px;
  color: white;
  margin: 35px auto 8px auto;
  display: block;
}

.login-box p {
  margin: 8px auto;
  display: block;
  text-align: center;
  color: var(--color-black);
}

.login-box p a:hover {
  color: var(--color-accent);
}

.login-button:hover {
  background-color: var(--color-accent-hover);
  cursor: pointer;
}

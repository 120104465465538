.detail-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  backdrop-filter: blur(3px);
  background-color: var(--color-black-trans);
  z-index: 50;
  top: 25px;
  left: 0;
  visibility: visible;
}

.detail-container {
  /* background-color: var(--color-grey-1); */
  /* background-color: rgba(237, 237, 237, 0.95); */
  background: var(--color-white);
  /* background: linear-gradient(112deg, rgba(255,255,255,.93) 35%, rgba(231, 231, 231, 0.90) 100%); */
  border-radius: 10px;
  top: 15%;
  width: 60vw;
  height: 75vh;
  margin: auto;
  padding: 15px 30px 30px 30px;
  min-width: 775px;
  overflow-y: scroll;
  color: var(--color-black);
}

.detail-container .col-4 {
  padding: 5px;
}

.close-icon {
  fill: var(--color-grey-3);
  width: 35px;
  height: 35px;
  position: sticky;
  top: 0%;
  left: 110%;
  padding: 5px;
  z-index: 2;
  margin-right: -7px;
}

.close-icon:hover {
  fill: var(--color-red);
  cursor: pointer;
}

.detail-container h1 {
  text-transform: uppercase;
  margin-top: -40px;
  text-align: center;
  font-size: 2.5rem;
}

.detail-container h2 {
  font-style: italic;
  font-weight: 450;
  text-align: center;
  margin-top: -17px;
  font-size: 1.4rem;
}

.detail-container h3 {
  font-size: 1.5rem;
  text-align: center;
  margin: 10px 0px;
}

.detail-container h4 {
  text-align: center;
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
}

.detail-container .text-container {
  background-color: var(--color-accent);
  border-radius: 50px;
  padding: 5px 25px;
  width: fit-content;
  margin: auto;
  transition: var(--fast-transition);
}

.detail-container .text-container:hover {
  transform: scale(105%);
  transition: var(--fast-transition);
  cursor: pointer;
}

.circular-progress-container {
  /* background: var(--color-white); */
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: var(--fast-transition);
}

.circular-progress-container:hover {
  opacity: 0.8;
  cursor: pointer;
  transition: var(--fast-transition);
  transform: scale(105%);
}

.circular-progress {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: conic-gradient(#7d2ae8 100deg, #ededed 0deg);
  background-color: #f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--default-transition);
}

.circular-progress::before {
  content: "";
  position: absolute;
  height: 130px;
  width: 130px;
  background-color: var(--color-white);
  border-radius: 50%;
}

.progress-value {
  position: relative;
  font-size: 50px;
  font-weight: 600;
  color: #7d2ae8;
}

.text {
  font-size: 30px;
  font-weight: 500;
  color: #606060;
}

text {
  fill: var(--color-black);
}

.circle-progress-container {
  width: 160px;
  height: 160px;
  /* background: var(--color-accent); */
  position: relative;
}

.circle-progress-container .outer {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px 1px rgba(255, 255, 255, 0.7);
  padding: 20px;
}

.circle-progress-container .inner {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset 4px 4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
}

.circle-progress-container #number {
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--color-gre-4);
}

.circle-progress-container circle {
  fill: none;
  stroke: var(--color-accent);
  stroke-width: 20px;
  stroke-dasharray: 472;
  stroke-dashoffset: 472;
  /* animation: anim 1s linear forwards; */
}

.circle-progress-container svg {
  position: absolute;
  top: 0;
  left: 0;
}

.technique-detail-container {
  width: 100%;
  background-color: var(--color-grey-1);
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 45px;
}

.technique-detail-container .technique-tag {
  background-color: var(--color-white);
  transition: var(--fast-transition);
}

.technique-detail-container .technique-tag p {
  color: var(--color-black);
}

.technique-detail-container .technique-tag:hover {
  cursor: pointer;
  box-shadow: none;
  transform: scale(105%);
  transition: var(--fast-transition);
}

.period-container {
  background-color: var(--color-blue-dark-2);
  border-radius: 25px;
  width: 90%;
  min-width: fit-content;
  margin: auto;
}

.period-name {
  text-align: center;
  color: var(--color-blue-dark-1);
  font-weight: 800;
  font-size: 1.5rem;
  transition: var(--fast-transition);
  padding-inline: 25px;
}

.period-name:hover {
  color: lightgray;
  cursor: pointer;
}

.period-name.match {
  color: white;
  background-color: var(--color-accent);
  transition: var(--fast-transition);
  border-radius: 25px;
}

.period-name.match:hover {
  transform: scale(105%);
  cursor: pointer;
}

.user-piece-date {
  text-align: center;
  font-size: 1.1rem;
}
